<template>
<div class="complainpreview">
  <!-- <div class="headTitle">
    投诉概览
  </div> -->
  <NavBar name="投诉概览"></NavBar>
  <div class="dataStatistics">
    <div class="title">
      市场投诉次数统计
    </div>
    <div class="box">
      <div class="list">
        <div class="sum">
          <span class="today">{{recentlyStatistics && recentlyStatistics.day_total || 0}}</span>次
        </div>
        <div class="name">
          今日新增投诉次数
        </div>
      </div>
      <div class="list">
        <div class="sum">
          <span class="formerly">{{recentlyStatistics && recentlyStatistics.past_total || 0}}</span>次
        </div>
        <div class="name">
          以往投诉次数
        </div>
      </div>
      <div class="list">
        <div class="sum">
          <span class="total">{{recentlyStatistics && recentlyStatistics.total || 0}}</span>次
        </div>
        <div class="name">
          总投诉次数
        </div>
      </div>
      <div class="list">
        <div class="sum">
          <span class="completed">{{recentlyStatistics && recentlyStatistics.status_total || 0}}</span>次
        </div>
        <div class="name">
          已处理投诉次数
        </div>
      </div>
    </div>
  </div>
  <div class="echarsBox">
    <div class="recently">
      <div class="title">
        <div class="left">
          最近投诉数据图
        </div>
        <div class="right">
          <el-date-picker v-model="date" @change="recentlyDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          <MyButton title="搜索" class="offset" @click="search">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" alt="">
            </template>
          </MyButton>
        </div>
      </div>
      <div class="recentlyBox">
        <div id="recentlyBox" style="width: 835px;height: 400px;"></div>
      </div>
    </div>
    <div class="annual offset">
      <div class="title">
        <div class="left">
          年度投诉数据趋势图
        </div>
        <div class="right">
          <el-date-picker v-model="year" @change="initAnnual" type="year" format="yyyy" value-format="yyyy" placeholder="选择年"></el-date-picker>
        </div>
      </div>
      <div class="recentlyBox">
        <div id="annualBox" style="width: 835px;height: 400px;"></div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  name: 'complainpreview',
  data() {
    return {
      form: {
        beginTime: '',
        endTime: ''
      },
      date: [],
      year: '',
      recentlyStatistics: null,
      recentlyMyChart: null,
      annualBoxMyChart: null
    };
  },
  created() {
    this.initRecently();
    this.initAnnual();
    this.$nextTick(() => {
      this.recentlyMyChart = echarts.init(document.getElementById('recentlyBox'));
      this.annualBoxMyChart = echarts.init(document.getElementById("annualBox"));
    });
  },
  methods: {
    recentlyDate(event) {
      if (event) {
        this.form.beginTime = event[0];
        this.form.endTime = event[1];
      } else {
        this.form.beginTime = '';
        this.form.endTime = '';
      }
    },
    search() {
      this.$request.HttpGet('/market_complaint/statisticsDays', this.form).then(res => {
        let dataX = [];
        let dataY = [];
        // this.recentlyMyChart.dispose();
        for (let i in res.data.listSevens) {
          dataX.push(res.data.listSevens[i].date);
          dataY.push(res.data.listSevens[i].total);
        }
        this.setRecentlyData({
          x: dataX,
          y: dataY
        });
      });
    },
    setRecentlyData(data) {
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          data: data.x
        },
        yAxis: {},
        series: [{
          name: '最近投诉',
          type: 'line',
          data: data.y,
          itemStyle: {
            normal: {
              label: {
                show: true
              },
              color: '#3D7EFF'
            }
          }
        }]
      };
      this.recentlyMyChart.setOption(option);
    },
    initRecently() {
      this.$request.HttpGet('/market_complaint/statistics').then(res => {
        this.recentlyStatistics = res.data.list;
        let dataX = [];
        let dataY = [];
        for (let i in res.data.listSevens) {
          dataX.push(res.data.listSevens[i].date);
          dataY.push(res.data.listSevens[i].total);
        }
        this.setRecentlyData({
          x: dataX,
          y: dataY
        });
      });
    },
    initAnnual(event) {
      if (event) {
        this.year = event;
      }
      this.$request.HttpGet('/market_complaint/statisticsYears', {
        year: this.year
      }).then(res => {
        let arrX = [];
        let arrY = []
        for (let i of res.data.listYears) {
          arrX.push(i.date);
          arrY.push(i.total);
        }
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            data: arrX
          },
          yAxis: {},
          series: [{
            name: '年度投诉',
            type: 'line',
            data: arrY
          }]
        };
        this.annualBoxMyChart.setOption(option);
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.complainpreview {
    .headTitle {
        height: 57px;
        line-height: 55px;
        border-bottom: 1px solid #DEDEDE;
        padding-left: 38px;
        font-size: 16px;
    }
    .dataStatistics {
        border: 1px solid #DEDEDE;
        margin-top: 30px;
        padding: 15px 20px;
        height: 160px;
        box-sizing: border-box;
        .title {
            font-size: 14px;
            color: #333333;
        }
        .box {
            display: flex;
            justify-content: center;
            .list {
                margin-top: 20px;
                width: 335px;
                padding-left: 120px;
                .sum {
                    font-size: 12px;
                    color: rgba(#333333, .6);
                    .today {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #E90000;
                        margin-right: 10px;
                    }
                    .formerly {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: rgba(#333333, .6);
                        margin-right: 10px;
                    }
                    .total {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #000000;
                        margin-right: 10px;
                    }
                    .completed {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #71D790;
                        margin-right: 10px;
                    }
                }
                .name {
                    margin-top: 10px;
                    font-size: 12px;
                    color: #333333;
                }
            }
        }
    }
    .echarsBox {
        display: flex;
        margin-top: 20px;
        .annual,
        .recently {
            flex: 1;
            height: 449px;
            border: 1px solid #DEDEDE;
            .title {
                display: flex;
                justify-content: space-between;
                .left {
                    margin-left: 20px;
                    font-size: 14px;
                    color: #000000;
                    margin-top: 15px;
                }
                .right {
                    margin-top: 20px;
                    margin-right: 60px;
                }
            }
        }
    }
}
</style>
